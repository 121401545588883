import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';

import Grid from '@mui/material/Grid';
import BannerSection from 'containers/SaasModern/Banner';

import WorkingProcessSection from 'containers/SaasModern/WorkingProcess';
import WorriesSection from 'containers/SaasModern/Worries';

import FeatureSection from 'containers/SaasModern/Feature';
import Footer from 'containers/SaasModern/Footer';

import Seo from 'components/seo';

import TrialSection from '../containers/SaasModern/Trial';
import Header from '../containers/SaasModern/Header';
import UpdateScreen from 'containers/SaasModern/UpdateScreen';
import QuestionForm from '../containers/SaasModern/Chat';

const SaasModern = () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          title='医療品在庫管理、自動発注システムpitto'
          description='このページは医療品在庫管理、自動発注システムpittoのページです'
        />
        <ResetCSS />
        <GlobalStyle />
        <Grid container>
          <Grid item xs={12}>
            <ContentWrapper>
              <Header></Header>
              <QuestionForm />
              <div id='header_banner'>
                <BannerSection />
                <div id='container'>
                  <WorriesSection />
                  <FeatureSection />
                  <UpdateScreen />
                  <WorkingProcessSection />
                </div>
              </div>
              <TrialSection />
              {/*導入事例が増えたら作る<TestimonialSection /> */}
              {/* <FaqSection /> */}
            </ContentWrapper>
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
      </Fragment>
    </ThemeProvider>
  );
};
export default SaasModern;
