import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/components/Box";
import Text from "common/components/Text";
import Heading from "common/components/Heading";
import Logo from "common/components/UIElements/Logo";
import Container from "common/components/UI/Container";
import FooterWrapper, { List, ListItem } from "./footer.style";
import { StaticImage } from "gatsby-plugin-image";
import LogoImage from "common/assets/image/saasModern/logo.png";

const Footer = ({ copyright }) => {
  return (
    <FooterWrapper>
      <Container className="footer_container">
        <a href="https://mappin.jp/">
          <div
            style={{ textAlign: "center", width: "200px", margin: "0 auto" }}
          >
            <StaticImage
              src="../../../common/assets/image/saasModern/mappin.png"
              alt="logo"
              quality="60"
              placeholder="blurred"
            />
          </div>
        </a>
        <div style={{ textAlign: "center", width: "200px", margin: "0 auto" }}>
          <Text {...copyright} content="©︎ mappin株式会社" />
        </div>
      </Container>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  copyright: PropTypes.object,
};

// Footer default style
Footer.defaultProps = {
  // Footer row default style
  row: {
    flexBox: true,
    flexWrap: "wrap",
    textAlign: "center",
    ml: "-15px",
    mr: "-15px",
  },
  copyright: {
    mt: "10px",
    fontSize: "14px",
    color: "#696969",
  },
};

export default Footer;
