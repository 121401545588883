import React from 'react';

import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';

import { StaticImage } from 'gatsby-plugin-image';
import { Fade, Grid, useMediaQuery } from '@mui/material';
import MediaQuery from 'react-responsive';

const WorriesSection = ({
  sectionWrapper,
  secTitleWrapper,
  secSubTitle,
  secDescription,
  secHeading,
  secText,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <div style={{ position: 'relative' }}>
      <Box
        {...sectionWrapper}
        as='section'
        style={{
          top: '30px',
          width: '100%',
          backgroundColor: '#F0F0F0',
        }}
      >
        <Grid container>
          <Grid item xs={1} md={3}></Grid>
          <Grid item xs={10} md={6}>
            <div {...secTitleWrapper}>
              <Heading {...secText} content='クリニックの在庫管理' />
              <Heading
                {...secHeading}
                content={
                  <div>
                    <span style={{ color: '#00a29a' }}>お困り</span>
                    ではありませんか？
                  </div>
                }
              />
            </div>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <StaticImage
                src='../../../common/assets/image/saasModern/worry_3.png'
                style={{
                  width: isMobile ? '50%' : '80%',
                }}
                alt='logo'
                quality='60'
                placeholder='blurred'
              />
              <Box
                style={{
                  margin: '0 auto',
                  marginBottom: '20px',
                  width: '80%',
                  height: isMobile ? '170px' : '250px',
                  backgroundColor: '#fff',
                }}
              >
                <Text
                  {...secSubTitle}
                  content={<span>診療中に欠品に気づく</span>}
                ></Text>
                <Text
                  {...secDescription}
                  content={
                    <span>
                      使いたいときに欠品に気づいたり、いつの間にか期限が切れていたり…
                      <br />
                      在庫がどこにいくつ、いつからあるのか誰も把握していない
                    </span>
                  }
                ></Text>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <StaticImage
                src='../../../common/assets/image/saasModern/worry_1.png'
                style={{
                  width: isMobile ? '50%' : '80%',
                }}
                alt='logo'
                quality='60'
                placeholder='blurred'
              />
              <Box
                style={{
                  margin: '0 auto',
                  marginBottom: '20px',
                  width: '80%',
                  height: isMobile ? '170px' : '250px',
                  backgroundColor: '#fff',
                }}
              >
                <Text
                  {...secSubTitle}
                  content={<span>発注作業が煩雑</span>}
                ></Text>
                <Text
                  {...secDescription}
                  content={
                    <span>
                      発注したい物品がどこに発注したらいいのか分からない
                      <br />
                      ディーラーごとにFAXを書いたり、メールを送ったり、作業が面倒
                    </span>
                  }
                ></Text>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <StaticImage
                src='../../../common/assets/image/saasModern/worry_2.png'
                style={{
                  width: isMobile ? '50%' : '80%',
                }}
                alt='logo'
                quality='60'
                placeholder='blurred'
              />
              <Box
                style={{
                  margin: '0 auto',
                  marginBottom: '20px',
                  width: '80%',
                  height: isMobile ? '170px' : '250px',
                  backgroundColor: '#fff',
                }}
              >
                <Text
                  {...secSubTitle}
                  content={<span>紙と手書きでの管理</span>}
                ></Text>
                <Text
                  {...secDescription}
                  content={
                    <span>
                      重複して発注してしまったり、
                      <br />
                      月末にノートを見ながら発注金額や在庫状況を集計していたりしていませんか？
                    </span>
                  }
                ></Text>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

WorriesSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object,
};

WorriesSection.defaultProps = {
  sectionWrapper: {
    width: ['800px', '1820px'],
    height: ['1900px', '800px', '900px', '960px', '1080px'],
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    width: ['300px', '1080px'],
    mb: ['60px', '100px'],
    textAlign: 'center',
  },
  secSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'cener',
    fontSize: ['14px', '14px', '15px', '18px', '20px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#3CAB9E',
    mt: '30px',
    pt: '20px',
  },
  secDescription: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: ['12px', '12px', '13px', '16px', '18px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#696969',
    mb: '15px',
    pb: '20px',
    ml: '20px',
    mr: '20px',
  },
  secContainer: {
    margin: '0 auto',
  },
  secSerif: {
    display: 'block',
    fontSize: '20px',
    fontSize: ['10px', '15px', '24px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: ['16px', '16px', '30px', '34px', '40px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#707070',
    mb: '5px',
    mt: '40px',
  },
  secImageWrapper: {
    width: '100%',
  },
  secHeading: {
    display: 'inline-block',
    fontSize: ['24px', '24px', '34px', '40px', '50px'],
    fontWeight: '700',
    color: '#707070',
    letterSpacing: '-0.025em',
    mb: '9',
    ml: '15px',
    lineHeight: '1.67',
  },
  secConclusion: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mt: '20',
    lineHeight: '1.67',
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-20px', '-35px', '-45px'],
    mr: ['0', '-15px', '-20px', '-35px', '-45px'],
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '20px', '35px', '45px'],
    pr: ['0', '15px', '20px', '35px', '45px'],
    mb: '40px',
  },
  processImageStyle: {
    width: ['60px', '60px', '70px', 'auto'],
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.02em',
    mb: ['20px', '20px', '22px', '22px', '22px'],
    mt: '35px',
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '15px', '15px'],
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.87',
  },
  imageWrapper: {
    width: '100%',
    height: '400px',
  },
  image: {
    right: '0px',
  },
};

export default WorriesSection;
