import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';

import Container from 'common/components/UI/Container';
import Text from 'common/components/Text';
import { BannerWrapper } from './banner.style';

import styles from './banner.css';
import { StaticImage } from 'gatsby-plugin-image';
import TiltShape from '../TiltShape';
import { Grid } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import url from '../../../common/assets/image/saasModern/pitto.png';
const BannerSection = ({
  row,
  contentWrapper,
  title,
  subTitle,
  secText,
  imageWrapper,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const pPosition = [
    { top: '5%', left: '10%', width: '20%' },
    { top: '10%', left: '40%', width: '30%' },
    { top: '0%', left: '80%', width: '50%' },
  ];

  return (
    <BannerWrapper id='banner_section'>
      <section class='container_back_back'>
        <div class='color'>
          <section class='container_back'>
            <div class='wave'> </div>
            {pPosition.map((position) => (
              <div
                style={{
                  position: 'absolute',
                  marginTop: position.top,
                  marginLeft: position.left,
                  opacity: '0.1',
                }}
              >
                <StaticImage
                  src='../../../common/assets/image/saasModern/pittop.png'
                  style={{
                    width: position.width,
                  }}
                  alt='logo'
                  quality='60'
                  placeholder='blurred'
                />
              </div>
            ))}

            <Container {...row}>
              <Grid container>
                <Grid item xs={12} sm={5} md={5} lg={7}>
                  <Heading
                    {...subTitle}
                    content='院内の物品をバーコードとアプリで'
                  />
                  <Heading {...title} content='自動管理 ・ 自動発注' />
                  <div class='btn_banner_wrapper'>
                    <a
                      href='https://tayori.com/form/09fcd93a0ceee3f0c98861cc234affbc09d6306e/'
                      class='btn_banner bgskei'
                    >
                      <span>無料体験お申し込み</span>
                    </a>
                  </div>
                </Grid>
                <Grid item sm={7} md={7} lg={5}>
                  <div
                    style={{
                      textAlign: 'right',
                      marginTop: isMobile ? '20px' : '250px',
                      width: isMobile ? '80%' : '100%',
                    }}
                  >
                    <StaticImage
                      src='../../../common/assets/image/saasModern/pitto_mockup.png'
                      style={{
                        width: '120%',
                      }}
                      alt='logo'
                      quality='60'
                      placeholder='blurred'
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Text
                    style={{
                      zIndex: '100',
                    }}
                    {...secText}
                    content={
                      <span>
                        <StaticImage
                          src='../../../common/assets/image/saasModern/pitto.png'
                          style={{
                            width: '65px',
                          }}
                          alt='logo_60'
                          quality='60'
                          placeholder='blurred'
                        />{' '}
                        {
                          '  は、クリニックのための医療物品管理・発注システムです。'
                        }
                        <br />
                        <br />
                        たくさんの医療材料や医薬品を使用しているクリニックにおいて、それぞれの数を把握し複数の業者に発注するのは難しい。
                        <br />
                        <br />
                        pittoはバーコードを読み取るだけで在庫の管理や発注作業を自動で行い、
                        <span style={{ color: '#00a29a' }}>
                          クリニック経営におけるコストの軽減
                        </span>
                        を可能にします。
                      </span>
                    }
                  ></Text>
                </Grid>
              </Grid>
            </Container>
          </section>
          {!isMobile && (
            <div class='scrolldown4'>
              <span>Scroll</span>
            </div>
          )}
        </div>
      </section>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  contentWrapper: {
    width: ['100%', '100%', '100%', '100%', '100%'],
    mb: '0px',
    height: ['87vh', '95vh'],
    textAlign: 'left',
  },
  subTitle: {
    fontSize: ['12px', '16px', '16px', '21px', '23px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    pt: ['10vh', '20vh'],
    lineHeight: '1.2',
    textStroke: '1px #FFF',
    textAlign: 'center',
  },
  title: {
    fontSize: ['24px', '24x', '24px', '40px', '40px'],
    fontWeight: '700',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '24px'],
    color: '#00a29a',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'left',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#ffdead',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: ['15px', '15px', '15px', '18px'],
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#696969',
    mt: ['100px', '100px', '50px', '20px', '0px'],
    zIndex: '100',
  },
  imageWrapper: {
    mt: ['100px', '100px', '50px', '20px', '300px'],
  },
};

export default BannerSection;
