import { useEffect, useRef } from 'react';
import React, { Fragment } from 'react';
const html = `<script>(function(t,a,y,o,r,i){r=a.createElement(y),i=a.getElementsByTagName(y)[0];r.type='text/javascript';r.async=1;r.src='//tayori.com/chat/05ca2138a2353786c1d166daa21501239190db63/tag.js';r.id='tayori-chat-tag';i.parentNode.insertBefore(r,i)})(window,document,'script');</script>`;
const REG_DETECT_SCRIPT_TAGS =
  /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gim;

function extractLocalContents(html) {
  return html.replace(REG_DETECT_SCRIPT_TAGS, (match) => {
    const scriptFragment = document
      .createRange()
      .createContextualFragment(match);
    const scriptElement = scriptFragment.querySelector('script');
    if (scriptElement.src === '') return match;
    return '';
  });
}

function extractExternalScriptElements(html) {
  const scriptElements = html.match(REG_DETECT_SCRIPT_TAGS);
  if (!scriptElements) return [];
  return scriptElements
    .map((match) => {
      const scriptFragment = document
        .createRange()
        .createContextualFragment(match);
      return scriptFragment.querySelector('script');
    })
    .filter((scriptElement) => scriptElement.src !== '');
}

function isScriptAlreadyInserted(scriptElement) {
  return Array.from(document.querySelectorAll('script')).some(
    (s) => s.src === scriptElement.src
  );
}

const  QuestionForm=({})=>{
  const element = useRef(null);
  useEffect(() => {
    if (!element.current) return;
    (async () => {
      const localContents = await extractLocalContents(html);
      const externalScriptElements = await extractExternalScriptElements(html);
      if (externalScriptElements) {
        await Promise.all(
          externalScriptElements.map((scriptElement) => {
            if (isScriptAlreadyInserted(scriptElement)) {
              return Promise.resolve();
            }

            return new Promise((resolve) => {
              scriptElement.addEventListener('load', resolve);
              document.head.appendChild(scriptElement);
            });
          })
        );
      }
      element.current.appendChild(
        document.createRange().createContextualFragment(localContents)
      );
    })();
  }, [element]);

  return (
    <div>
      <div ref={element} />
    </div>
  );
};
export default QuestionForm;

